import { useEffect, useRef, useState } from "react";

const useInternalStorage = <T>(key: string, defaultValue: T,
  getStorageEngine: () => typeof localStorage | typeof sessionStorage, retAsObject = false, undefinedValue?: T)
  : [T, React.Dispatch<React.SetStateAction<T>>] => {

  const storage = useRef<Storage>()
  const [state, setState] = useState<T>(undefinedValue ?? defaultValue);

  // useEffect(() => {
  //   setState(undefinedValue ?? defaultValue);
  // }, [])


  useEffect(() => {
    if (!storage.current) {
      storage.current = getStorageEngine();
    }
    const val = storage.current.getItem(key);
    if (val) {
      const st = retAsObject ? JSON.parse(val ?? '{}') : val;
      setState(st);
    }
  }, []);

  const setWithStorage = (...[nextState]: Parameters<typeof setState>) => {
    let newState;
    if (typeof nextState === 'function') {
      // @ts-ignore
      newState = nextState(state);
    } else {
      newState = nextState;
    }
    if (state !== newState) {
      setState(newState);
    }
    if (storage.current) {
      if (retAsObject) {
        return storage.current.setItem(key, JSON.stringify(newState));
      }
      storage.current.setItem(key, newState);
    }
  };

  return [state, setWithStorage];
}

type RT<T> = [T, React.Dispatch<React.SetStateAction<T>>, string]

export function useBrowserStorage(key: string, defaultValue: string) {
  const stateHandler = useInternalStorage(key, defaultValue, () => localStorage, false)
  return [...stateHandler, key] as const;
}


export function useBrowserStorageObject<T = object>(key: string, defaultValue: T, undefinedValue?: T) {
  const [s, setS] = useInternalStorage(key, defaultValue, () => localStorage, true, undefinedValue)
  return [s, setS, key] as const;
}

export function useSessionStorage(key: string, defaultValue: string) {
  const stateHandler = useInternalStorage(key, defaultValue, () => sessionStorage)
  return [...stateHandler, key] as const;
}

export function useSessionStorageObject<T = object>(key: string, defaultValue: T, undefinedValue?: T) {
  const [s, setS] = useInternalStorage(key, defaultValue, () => sessionStorage, true, undefinedValue)
  return [s, setS, key] as const;
}
